import {MeetingRoomBooking} from "../../API";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getMeetingRoomType, MeetingType} from "../../Utils/Enums";
import SaveIcon from "@material-ui/icons/Save";
import {gql, useMutation} from "@apollo/client";
import {updateSecureMeetingRoomBooking} from "../../graphql/mutations";
import {useErrorContext} from "../../hooks/useErrorContext";
import Alert from "@material-ui/lab/Alert";
import {isBookingOverlapping, isBookingTimeValid,} from "../../Utils/MeetingRoomBookingEditComponentHelper";
import TimeWindowTableComponent from "../MultiBookingDialog/TimeWindowTableComponent";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {columnStyleFixedWidth} from "../../Utils/commons";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import MeetingRoomBookingSettings, {SeatOptionI} from "../MultiBookingDialogMeetingRoom/MeetingRoomBookingSettings";
import useMeetingRoomBookingSettingsStorage from "../../hooks/useMeetingRoomBookingSettingsStorage";
import {Box, Tab, Tabs} from "@mui/material";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {ManagePermission} from "../../types/PermissionHandling";
import {useBookingsByMeetingRoom} from "../../hooks/useBookingsByMeetingRoom";
import {OwnMeetingRoomBooking} from "../../types/MeetingRoomBookingListItemType";
import DoubleTimePicker from "../MultiBookingDialog/DoubleTimePicker";
import DeleteButton from "../Buttons/DeleteButton";
import CloseButton from "../Buttons/CloseButton";

interface Props {
    bookingToEdit: OwnMeetingRoomBooking & ManagePermission
    refetchBookings: () => void
    setShowEditDialog: (showEditDialog: boolean) => void
    show: boolean
    onAttemptDeleteBooking: (booking: MeetingRoomBooking) => void
    hideDeleteButton: boolean
}

const MeetingRoomBookingEditComponent: React.FC<Props> = props => {
    const {
        bookingToEdit,
        refetchBookings,
        setShowEditDialog,
        show,
        onAttemptDeleteBooking,
        hideDeleteButton
    } = props;
    const bookingsOfMeetingRoom = useBookingsByMeetingRoom(bookingToEdit ? bookingToEdit.roomId : undefined, bookingToEdit ? bookingToEdit.date : undefined, bookingToEdit ? bookingToEdit.meetingRoomId : undefined);
    const {meetingRoomBookingSettings, setMeetingRoomBookingSettings} = useMeetingRoomBookingSettingsStorage();
    const [beginTime, setBeginTime] = useState<Dayjs | null>(bookingToEdit?.timeBegin ? dayjs(bookingToEdit.timeBegin) : null);
    const [endTime, setEndTime] = useState<Dayjs | null>(bookingToEdit?.timeEnd ? dayjs(bookingToEdit.timeEnd) : null);
    const [timeValid, setTimeValid] = useState(false);
    const [isInvalidBookingTimeEndTimeIsInPast, setIsInvalidBookingTimeEndTimeIsInPast] = useState(false);
    const [updateMeetingRoomBookingMutation] = useMutation(gql(updateSecureMeetingRoomBooking))
    const {reportError} = useErrorContext()
    const [currentMobileTab, setCurrentMobileTab] = useState(0);
    const {isNoFullscreen, shouldAlignButtons} = useDeviceMediaType()
    const {t} = useTranslation();
    let isBookingTimeComplete = !!(beginTime?.isValid() && endTime?.isValid())
    let showBookingTimeAlert = isBookingTimeComplete && !timeValid
    let showBookingTimeEndTimeInPastAlert = isBookingTimeComplete && isInvalidBookingTimeEndTimeIsInPast
    useEffect(function initializeFormData() {
        let mt = MeetingType.INTERNAL;
        switch (bookingToEdit?.meetingType ?? "INTERNAL") {
            case MeetingType.VIP.toString():
                mt = MeetingType.VIP;
                break;
            case MeetingType.EXTERNAL.toString():
                mt = MeetingType.EXTERNAL;
                break;
        }
        setMeetingRoomBookingSettings(
            {
                meetingName: bookingToEdit?.meetingName ?? "",
                participantNumber: bookingToEdit?.participantNumber ?? null,
                meetingType: mt,
                visitors: bookingToEdit?.visitors ?? "",
                seatConfId: bookingToEdit?.meetingRoomSeatConfId
            }
        )

        setBeginTime(bookingToEdit?.timeBegin ? dayjs(bookingToEdit.timeBegin) : null);
        setEndTime(bookingToEdit?.timeEnd ? dayjs(bookingToEdit.timeEnd) : null);
    }, [bookingToEdit]);

    useEffect(function validateBookingTimeOnChange() {
        const isTimeValid = isBookingTimeValid(beginTime, endTime)
            && !isBookingOverlapping(beginTime, endTime, bookingToEdit, bookingsOfMeetingRoom)
        setTimeValid(isTimeValid);
        if (endTime && bookingToEdit) {
            setIsInvalidBookingTimeEndTimeIsInPast(inputEndTimeIsInPast(endTime, bookingToEdit));
        } else {
            setIsInvalidBookingTimeEndTimeIsInPast(false);
        }
    }, [beginTime, endTime]);
    if (bookingToEdit === null) {
        return <></>
    }

    const handleStartTime = (newValue: Dayjs | null) => { // millisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setBeginTime(newValue.millisecond(0)) : setBeginTime(newValue)
    }
    const handleEndTime = (newValue: Dayjs | null) => { // millisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setEndTime(newValue.millisecond(0)) : setEndTime(newValue)
    }

    function handleOnClose() {
        setShowEditDialog(false);
    }

    function handleSave() {
        validateInputsOnSave();
        if (!timeValid) {
            return;
        }
        const editedBooking: MeetingRoomBooking = {
            ...bookingToEdit,
            meetingName: meetingRoomBookingSettings.meetingName.trim(),
            participantNumber: Number(meetingRoomBookingSettings.participantNumber),
            meetingType: meetingRoomBookingSettings.meetingType,
            timeBegin: beginTime?.toISOString(),
            timeEnd: endTime?.toISOString(),
            visitors: meetingRoomBookingSettings.visitors.trim(),
            meetingRoomSeatConfId: meetingRoomBookingSettings.seatConfId
        }
        if (!wasBookingChanged()) {
            handleOnClose();
            return;
        }
        handleOnSaveBooking(editedBooking);

        function wasBookingChanged() {
            return !(editedBooking.meetingName === bookingToEdit.meetingName
                && editedBooking.participantNumber === bookingToEdit.participantNumber
                && editedBooking.meetingType === bookingToEdit.meetingType
                && editedBooking.timeBegin === bookingToEdit.timeBegin
                && editedBooking.timeEnd === bookingToEdit.timeEnd
                && editedBooking.visitors === bookingToEdit.visitors
                && editedBooking.meetingRoomSeatConfId === bookingToEdit.meetingRoomSeatConfId);
        }

        function handleOnSaveBooking(booking: MeetingRoomBooking) {
            updateMeetingRoomBookingMutation({
                variables: {
                    input: {
                        bookingId: booking.bookingId,
                        date: booking.date,
                        meetingRoomId: booking.meetingRoomId,
                        roomId: booking.roomId,
                        bookerId: booking.bookerId,
                        bookerName: booking.bookerName,
                        bookerGivenName: booking.bookerGivenName,
                        bookerFamilyName: booking.bookerFamilyName,
                        orgUnitId: booking.orgUnitId,
                        timeBegin: booking.timeBegin,
                        timeEnd: booking.timeEnd,
                        meetingName: booking.meetingName?.trim() ?? "",
                        roomCapacity: booking.roomCapacity ?? 0,
                        participantNumber: booking.participantNumber,
                        meetingType: booking.meetingType,
                        visitors: booking.visitors,
                        meetingRoomSeatConfId: booking.meetingRoomSeatConfId
                    }
                }
            })
                .then(() => setShowEditDialog(false))
                .then(() => refetchBookings())
                .catch((err) => reportError(err, err.message, "MeetingRoomBookingManagerComponent UpdateMeetingRoomBooking "))
        }

        function validateInputsOnSave(): void {
            const isNameValid = meetingRoomBookingSettings.meetingName.trim() !== ""
            const isTimeValid = isBookingTimeValid(beginTime, endTime);
            let isOverlapping = true;
            if (isTimeValid) {
                isOverlapping = isBookingOverlapping(beginTime, endTime, bookingToEdit, bookingsOfMeetingRoom);
            }
            setTimeValid(isNameValid && isTimeValid && !isOverlapping);

            if (endTime && bookingToEdit) {
                setIsInvalidBookingTimeEndTimeIsInPast(inputEndTimeIsInPast(endTime, bookingToEdit))
            } else {
                setIsInvalidBookingTimeEndTimeIsInPast(false)
            }
        }
    }


    function inputEndTimeIsInPast(endTime: Dayjs, bookingToEdit: MeetingRoomBooking): boolean {
        const today = new Date();
        const day = new Date(bookingToEdit.date);
        day.setHours(endTime.hour(), endTime.minute(), 0, 0);

        return day.getTime() < today.getTime();
    }

    function handleClickOnTimeWindow(start: Dayjs, end: Dayjs) {
        setBeginTime(start);
        setEndTime(end);
    }

    function getTimeWindow() {
        return (
            <div style={columnStyleFixedWidth}>
                <TimeWindowTableComponent
                    bookings={bookingsOfMeetingRoom}
                    selectedDates={[new Date(bookingToEdit.date)]}
                    handleClickOnTimeWindow={handleClickOnTimeWindow}
                />
            </div>
        )
    }

    function getSettings() {
        return (
            <div style={columnStyleFixedWidth}>
                <MeetingRoomBookingSettings
                    roomId={bookingToEdit.roomId}
                    meetingRoomId={bookingToEdit.meetingRoomId}
                    maxParticipants={bookingToEdit.roomCapacity ?? null}
                    meetingRoomType={getMeetingRoomType(bookingToEdit.meetingRoomType)}
                    settings={meetingRoomBookingSettings}
                    setSettings={setMeetingRoomBookingSettings}
                    selectedDates={[]}
                    setCurrentSeatConfigSetting={() => {}}
                />
                <div className={"leftSideChild"} style={{display: "flex", marginTop: "10px"}}>
                    <DoubleTimePicker startTime={beginTime} onChangeStartTime={handleStartTime} endTime={endTime}
                                      onChangeEndTime={handleEndTime}/>
                </div>
            </div>
        )
    }

    const isSaveButtonDisabled = !timeValid || meetingRoomBookingSettings.meetingName.trim() === "" || !Number.isInteger(meetingRoomBookingSettings.participantNumber)
        || isInvalidBookingTimeEndTimeIsInPast;

    function hasBookingPermission() {
        return bookingToEdit.managingAllowed
    }

    function getAlertContent() {
        if (showBookingTimeAlert) {
            return t("multibookingdialog_error_time_slot_not_bookable")
        } else if (showBookingTimeEndTimeInPastAlert) {
            return t("multibookingdialog_error_time_slot_not_bookable_endtime_is_in_past_cannot_edit")
        }
    }

    const handleMobileTabChange = (event: React.SyntheticEvent, newVal: number) => {
        setCurrentMobileTab(newVal);
    }

    const shouldRenderTab = (value: number) => {
        if (!isNoFullscreen) {
            return true;
        }
        return value === currentMobileTab;
    }

    function getMobileDialogContent() {
        return (
            <>
                <Box className={"dialogTab"} sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                }}>
                    <Tabs value={currentMobileTab} onChange={handleMobileTabChange}
                          variant={"scrollable"} scrollButtons={true} allowScrollButtonsMobile={true}>
                        <Tab label={t('multibookingdialog_meetingroom_settings_title')}/>
                        <Tab label={t("multibookingdialog_time_window_title")}/>
                    </Tabs>
                </Box>
                <Box className={"dialogTab"}>
                    {shouldRenderTab(0) && getSettings()}
                    {shouldRenderTab(1) && getTimeWindow()}
                </Box>
            </>
        )
    }

    function getDesktopDialogContent() {
        return (
            <Box className={"dialogTab"}>
                {getSettings()}
                <div className={"verticalLine"}/>
                {getTimeWindow()}
            </Box>
        );
    }


    return <Dialog maxWidth="md" open={show} scroll={"paper"} data-testid="edit-meeting-room-bookings-dialog-testid">
        {(showBookingTimeAlert || showBookingTimeEndTimeInPastAlert) &&
            <Alert severity="error" className={"alertStyle"}>
                {getAlertContent()}
            </Alert>}
        <DialogTitle>{t("edit-meeting-room-bookings")}</DialogTitle>
        <DialogContent>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                {isNoFullscreen ? getMobileDialogContent() : getDesktopDialogContent()}
            </LocalizationProvider>
        </DialogContent>
        <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
            <Button
                onClick={handleSave}
                size={shouldAlignButtons ? "small" : "medium"}
                color="primary"
                variant="contained"
                disabled={isSaveButtonDisabled || !hasBookingPermission()}
                startIcon={!shouldAlignButtons && <SaveIcon/>}>
                {t("button_save")}
            </Button>
            {!hideDeleteButton &&
                <DeleteButton
                    disabled={!hasBookingPermission()}
                    onClick={() => onAttemptDeleteBooking(bookingToEdit)}
                    size={shouldAlignButtons ? "small" : "medium"}
                    data-testid={"btn-delete-edit-room-booking"}>
                    {t('delete')}
                </DeleteButton>
            }
            <CloseButton
                onClick={handleOnClose}
                size={shouldAlignButtons ? "small" : "medium"}
                data-testid={"btn-close"}>
            </CloseButton>
        </DialogActions>
    </Dialog>
}
export default MeetingRoomBookingEditComponent
