import React, {useCallback, useEffect, useState} from "react";
import {DialogContent, Tab, Tabs} from "@mui/material";
import {OwnSeatBookingsTabComponent} from "./OwnSeatBookingsTabComponent";
import {Dialog, DialogActions, DialogTitle, makeStyles} from "@material-ui/core";
import {OwnMeetingBookingsTabComponent} from "./OwnMeetingBookingsTabComponent";
import {useTranslation} from "react-i18next";
import {useDeviceMediaType} from "../../hooks/useDeviceMediaType";
import {OwnBookingsDeleteDialog} from "./OwnBookingsDeleteDialog";
import {DialogButton} from "../Buttons/DialogButton";
import {useOwnDetailedMRBookings} from "../../hooks/bookings/useOwnDetailedMRBookings";
import {useOwnDetailedSeatBookings} from "../../hooks/bookings/useOwnDetailedSeatBookings";
import MeetingRoomBookingEditComponent from "../MeetingRoomBookingManager/MeetingRoomBookingEditComponent";
import DeleteButton from "../Buttons/DeleteButton";
import CloseButton from "../Buttons/CloseButton";
import Edit from "@material-ui/icons/Edit";

export enum SelectedTab {
    SEAT_BOOKING_TAB,
    MEETING_ROOM_BOOKING_TAB
}

export interface OwnBookingsProps {
    showOwnBookingsManagerComponent: boolean
    setShowOwnBookingsManagerComponent: (value: boolean) => void
}

const OwnBookingsManagerComponent: React.FC<OwnBookingsProps> = (props) => {
    const {
        showOwnBookingsManagerComponent,
        setShowOwnBookingsManagerComponent,
    } = props;
    const [tabIndex, setTabIndex] = useState<SelectedTab>(SelectedTab.SEAT_BOOKING_TAB);

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [showMRBookingEditDialog, setShowMRBookingEditDialog] = useState<boolean>(false);
    const {
        areMRBookingsloading,
        selectedMRBookings,
        onSelectMRBookings,
        detailedMRBookings,
        refetchMRBookings,
        deleteSelectedMRBookingsAndRefetch
    } = useOwnDetailedMRBookings();

    const {
        areSeatBookingsLoading,
        detailedSeatBookings,
        selectedSeatBookings,
        onSelectSeatBookings,
        refetchSeatBookings,
        deleteSelectedSeatBookingsAndRefetch
    } = useOwnDetailedSeatBookings();

    const {t} = useTranslation();

    useEffect(function fetchBookings() {
        // fetch bookings when dialog is opened
        if (!showOwnBookingsManagerComponent) {
            return;
        }
        refetchSeatBookings().then();
        refetchMRBookings().then();
    }, [showOwnBookingsManagerComponent]);

    // For passing to edit dialog
    const refetchMRBookingsFromEditDialog = useCallback(() => {
        refetchMRBookings().then()
    }, [refetchMRBookings]);

    function handleDeleteSelectedBookings() {
        const deleteBasedOnActiveTab = async () =>
            (seatBookingTabVisible ? deleteSelectedSeatBookingsAndRefetch() : deleteSelectedMRBookingsAndRefetch());

        deleteBasedOnActiveTab()
            .finally(() => setShowDeleteDialog(false));
    }

    const useStyles = makeStyles({
        dialogPaper: {
            height: '60vh',
            overflowY: "auto"
        },
    })

    const {isMobile, isNoFullscreen} = useDeviceMediaType();
    const classes = useStyles()

    function handleCloseBookingManagerDialog(): void {
        setTabIndex(SelectedTab.SEAT_BOOKING_TAB);
        setShowOwnBookingsManagerComponent(false);
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: SelectedTab) => {
        onSelectMRBookings([]);
        onSelectSeatBookings([]);
        setTabIndex(newValue);
    };

    const seatBookingTabVisible = tabIndex === SelectedTab.SEAT_BOOKING_TAB;
    const mrBookingTabVisible = !seatBookingTabVisible;

    const deleteButtonDisabled = (mrBookingTabVisible && selectedMRBookings.length === 0)
        || (seatBookingTabVisible && selectedSeatBookings.length === 0);
    const editButtonDisabled = !(mrBookingTabVisible && selectedMRBookings.length === 1);
    const numberOfBookingsToDelete = seatBookingTabVisible ? selectedSeatBookings.length : selectedMRBookings.length;

    return (
        <Dialog classes={{paper: classes.dialogPaper}}
                open={showOwnBookingsManagerComponent}
                fullWidth={true}
                maxWidth={"lg"}
                data-testid={"own-bookings-dialog-test-id"}
                onClose={handleCloseBookingManagerDialog}>
            <DialogTitle>
                {t("own_bookings_management_dialog_title")}
            </DialogTitle>
            <DialogContent>
                <Tabs value={tabIndex} onChange={handleChangeTab} variant={isMobile ? "scrollable" : "fullWidth"}
                      scrollButtons={isMobile}
                      allowScrollButtonsMobile={isMobile}>
                    <Tab label={t("seats")} value={SelectedTab.SEAT_BOOKING_TAB}></Tab>
                    <Tab label={t("meeting_rooms")} value={SelectedTab.MEETING_ROOM_BOOKING_TAB}></Tab>
                </Tabs>
                {seatBookingTabVisible &&
                    <OwnSeatBookingsTabComponent
                        showLoadingIndicator={areSeatBookingsLoading}
                        bookings={detailedSeatBookings}
                        selectedBookings={selectedSeatBookings}
                        onBookingsSelect={onSelectSeatBookings}
                    />}
                {mrBookingTabVisible &&
                    <OwnMeetingBookingsTabComponent
                        showLoadingIndicator={areMRBookingsloading}
                        bookings={detailedMRBookings}
                        onSelectMRBookings={onSelectMRBookings}
                        selectedMRBookings={selectedMRBookings}
                    />}
            </DialogContent>

            <DialogActions className={isNoFullscreen ? "dialogActionsMobile" : "dialogActions"}>
                <CloseButton
                    style={{visibility: 'hidden', display: isNoFullscreen ? 'none' : 'flex'}}
                    data-testid={"btn-close-hidden"}
                ></CloseButton>
                <div style={{flex: "1 1 auto"}}></div>
                <DeleteButton
                    disabled={deleteButtonDisabled}
                    size={isMobile ? 'small' : 'medium'}
                    data-testid={"btn-delete"}
                    onClick={() => setShowDeleteDialog(true)}>
                    {t('delete')}
                </DeleteButton>
                <DialogButton
                    disabled={editButtonDisabled}
                    size={isMobile ? 'small' : 'medium'}
                    startIcon={!isMobile && <Edit/>}
                    data-testid={"btn-edit"}
                    onClick={() => setShowMRBookingEditDialog(true)}
                >
                    {t("edit")}
                </DialogButton>
                <div style={{flex: "1 1 auto"}}></div>
                <CloseButton
                    size={isMobile ? 'small' : 'medium'}
                    onClick={handleCloseBookingManagerDialog}
                    data-testid={"btn-close"}
                ></CloseButton>
            </DialogActions>
            <OwnBookingsDeleteDialog
                isOpen={showDeleteDialog}
                onDelete={handleDeleteSelectedBookings}
                onClose={() => setShowDeleteDialog(false)}
                numberOfBookingsToDelete={numberOfBookingsToDelete}/>
            {selectedMRBookings.length === 1 &&
                <MeetingRoomBookingEditComponent
                    bookingToEdit={selectedMRBookings[0]}
                    refetchBookings={refetchMRBookingsFromEditDialog}
                    setShowEditDialog={setShowMRBookingEditDialog}
                    show={showMRBookingEditDialog}
                    hideDeleteButton={true}
                    onAttemptDeleteBooking={() => {/*deletion not needed*/}}
                />}
        </Dialog>
    );
}
export default OwnBookingsManagerComponent

